.modal-full-width {
	max-width: 100% !important;
	margin: 30px 10px !important;
}

.modal-header {
	background-color: #ac0101;
}

.modal-title {
	color: #fff;
}

.navbarBottom {
	margin-top: 30px;
}
