// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

$gray-dark: #ac0101 !default;

$enable-flex: true;

$navbar-light-hover-color: #ac0101;

$brand-primary: #ac0101 !default;

$card-cap-bg: #ac0101 !default;

$card-cap-color: #ffffff;

$navbar-top-background: #ac0101 !default;
$navbar-bottom-background: #ac0101 !default;

$navbar-top-color: #fff;
$navbar-top-active-color: #e5e500;
$navbar-top-hover-color: #e2e2e2;

$navbar-bottom-color: #fff;
$navbar-bottom-active-color: #e5e500;
$navbar-bottom-hover-color: #e2e2e2;

$nav-tabs-active-link-hover-bg: #ac0101;
$nav-tabs-active-link-hover-color: #fff;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$red: #dc3545 !default;
$yellow: #ffff00 !default;
$green: #28a745 !default;
$cyan: #17a2b8 !default;

$theme-colors: (
	primary: #ac0101,
	secondary: $gray-600,
	success: $green,
	info: $cyan,
	warning: $yellow,
	danger: $red,
	light: $gray-100,
	dark: $gray-800,
) !default;

@import '~bootstrap/scss/bootstrap.scss';

@import '../../../../vendor/z5internet/demora/src/resources/assets/scss/scss.scss';

.navbarBottom {
	position: relative;
}

body {
	padding-bottom: 0px !important;
}

#appContainer {
	display: flex;

	> div {
		flex-grow: 1;
	}
}

#pageContent {
	min-height: 90vh;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

#routerRoot {
	flex-grow: 1;
}

.background-yellow {
	background-color: #ff0;
}

.navbarTop .nav.navbar-left .screen650 {
	@media only screen and (max-width: 650px) {
		display: none;
	}
}
