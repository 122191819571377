.NewPlanCountdown {
	background: #000048;
	.countdown {
		> div {
			display: flex;
			> div {
				font-size: 2rem;
				display: table-cell;
				vertical-align: bottom;
				align-self: flex-end;
			}
			> div:first-child {
				margin-right: 3px;
			}
			> div:last-child {
				font-size: 0.9rem;
			}
			text-align: center;
			margin: 0 5px;
			width: auto;
		}
	}

	@media (max-width: 575px) {
		.countdown div {
			width: 70px;
		}
	}

	.NewPlanCountdown-inner {
		display: inline-block;
	}

	.btn-xlg {
		padding: 1rem 2rem;
		font-size: 2.55rem;
		border-radius: 0.6rem;
	}
}
